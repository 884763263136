<template>
  <div v-if="authUser" class="container-fluid">
    <div class="row">
      <div class="col">
        <PageTitle :title="'Metric'"></PageTitle>
        <ClientMetric :userId="authUser.userId" :compactMode="true"></ClientMetric>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Metric',
  components: {
    PageTitle: () => import('@/components/PageTitle'),
    ClientMetric: () => import('@/components/ClientMetric'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
  },
};
</script>
